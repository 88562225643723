@font-face {
    font-family: 'Elemental End';
    font-style: normal;
    font-weight: 400;
    src: local('Elemental End'),
        url('./fonts/Elemental End/Elemental End.ttf') format('truetype');
}

* {
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', 'Sans-serif';
    background-color: #6d819c;
    color: #e4e7ec;
    /* background-image: url('https://images.pexels.com/photos/1250260/pexels-photo-1250260.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'); */
}

h1,
h2,
h3 {
    font-family: 'Elemental End', 'Roboto';
}

.header-title {
    color: #263959;
}

.default-text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Elemental End';
    color: #6d819c;
}

.item-amount {
    font-family: monospace;
    font-size: 11px;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: none !important;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #263959 inset;
    -webkit-text-fill-color: #e4e7ec;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #263959 inset;
    -webkit-text-fill-color: #e4e7ec;
}

.no-image {
    filter: invert(0.9);
}

a {
    color: #55967e;
    font-weight: bold;
    text-decoration: unset;
    transition: 200ms color;
}

a:hover {
    color: #e4e7ec;
}

.red {
    color: #ec7063;
}

.green {
    color: #52be80;
}

.yellow {
    color: #f4d03f;
}

.blue {
    color: #5dade2;
}

p {
    margin-bottom: 16px !important;
}
